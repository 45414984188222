import { MantineProvider } from "@mantine/styles";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        colors: {
          brand: [
            "#dce7ff",
            "#b4f1fc",
            "#93c1f6",
            "#78dfed",
            "#628be3",
            "#3f6ce6",
            "#1c8eec",
            "#096fe4",
            "#0093d7",
            "#009bca",
          ],
        },
        primaryColor: "brand",
        loader: "dots",
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
