import React, {useState} from 'react';
import {Paper } from '@mantine/core';
import ComplicationSelect from 'components/ComplicationSelect';

function Wizard() {


  return (
    <>
    <div style={{ width: 500, margin: 'auto' }}>
      <Paper padding="lg" shadow="xs">
        <ComplicationSelect />
      </Paper>
    </div>

    </>
  );
}

export default Wizard;


