/* import { SimpleGrid, Text } from "@mantine/core";
import { isEmptyNameAtom } from "atoms/greetingBox"; */
import { MainTemplate } from "components/template";
import Wizard from "components/Wizard";
/* import { useAtom } from "jotai";
import UsersList from "modules/Users";
import GreetingBox from "../components/GreetingBox"; */

const Home = () => {
 /*  const [isEmptyName] = useAtom(isEmptyNameAtom);

  const isEmpty = isEmptyName ? "empty" : "not empty"; */

  return (
    <MainTemplate>
        <Wizard />
    </MainTemplate>
  );
};

export default Home;
