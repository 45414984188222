import React, {useState} from 'react';
import { forwardRef } from 'react';
import { Group, Text, MultiSelect, Slider, Button, Container, Modal, Alert  } from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { FiAlertCircle, FiSmile } from "react-icons/fi";


const data = [
  {
    label: 'Diabetes/Hypertension',
    value: 'Diabetes',
    description: 'Report Diabetes or Hypertension',
  },
  {
    label: 'Placenta Praevia',
    value: 'Placenta Praevia',
    description: 'Report Placenta Praevia',
  },
  {
    label: 'Severe Oligohydramnios',
    value: 'Severe Oligohydramnios',
    description: 'Report Severe Oligohydramnios',
  },
  {
    label: 'Moderate Oligohydramnios',
    value: 'Moderate Oligohydramnios',
    description: 'Report Moderate Oligohydramnios',
  },
  {
    label: 'Mild Oligohydramnios',
    value: 'Mild Oligohydramnios',
    description: 'Report Mild Oligohydramnios',
  },
  {
    label: 'Intra-Uterine Fetal Death (IUFD)',
    value: 'Intra-Uterine Fetal Death (IUFD)',
    description: 'Report Intra-Uterine Fetal Death (IUFD)',
  },
  {
    label: 'Big baby >3.5Kg',
    value: 'Big baby >3.5Kg',
    description: 'Report Big baby >3.5Kg',
  },
];

type ItemProps = any;

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

function ComplicationSelect() {

  const [complicationState, setcomplicationState] = useState({
    score: "  Low Risk",
    comments: "Refer",
    category: "Low Risk"
  });
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      complication: [],
      gestation: 0,
    },
  });

/*   const [complicationValue, setComplicationValue] = useInputState(['']);
  const [gestationValue, setGestationValue] = useInputState(0);

  console.log(complicationValue,gestationValue); */
  interface ScoringType {
    complication: Array<String>,
    gestation: number,
  }

  function handleSubmit(values : ScoringType) {
    let {complication, gestation} = values;
    
   if (complication.includes('Intra-Uterine Fetal Death (IUFD)') && gestation > 16) {
     const comment_obj = {
       score: "High Risk",
       comments: "Refer",
       category: "High Risk"
     }
      console.log(complicationState);
      setcomplicationState(comment_obj)

    } else if (complication.includes('Placenta Praevia') && gestation >= 36) {
      const comment_obj = {
        score: "High Risk",
        comments: "Refer",
        category: "High Risk"
      }
      console.log(complicationState);
      setcomplicationState(comment_obj)
    
    } else if (complication.includes('Placenta Praevia') && gestation < 30) {
      const comment_obj = {
        score: "Low Risk",
        comments: "Don’t refer (Monitor with scan until 36 weeks)",
        category: "Low Risk"
      }
      console.log(complicationState);
      setcomplicationState(comment_obj)

    } else if (complication.includes('Severe Oligohydramnios') && gestation >= 36) {
      const comment_obj = {
        score: "High Risk",
        comments: "Refer",
        category: "High Risk"
      }
      console.log(complicationState);
      setcomplicationState(comment_obj)

    } else if (complication.includes('Big baby >3.5Kg') && gestation >= 36) {
      const comment_obj = {
        score: "High Risk",
        comments: "Refer",
        category: "High Risk"
      }
      console.log(complicationState);
      setcomplicationState(comment_obj)

    } else if (complication.includes('Moderate Oligohydramnios') && gestation < 36) {
      const comment_obj = {
        score: "Low Risk",
        comments: "Don’t refer (Monitor with scan until 36 weeks)",
        category: "Low Risk"
      }
      console.log(complicationState);
      setcomplicationState(comment_obj)
    }

    setOpened(true)

  }

  let alertRenderer;
  if (complicationState.category === "High Risk")  {
    alertRenderer =  <Alert icon={<FiAlertCircle size={16} />} title={complicationState.category} color="red" variant="filled">
                      {complicationState.comments}
                      </Alert>
  } else {
    alertRenderer =  <Alert icon={<FiSmile size={16} />} title={complicationState.category} color="green" variant="filled">
                      {complicationState.comments}
                      </Alert>
  }
  
  return (
    <div>
       <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        centered
      >
        {alertRenderer}
      </Modal>
      <form onSubmit={form.onSubmit(handleSubmit)}>
          <Container
            padding="lg"
            style={{ marginTop: 10 }}
          >
            <MultiSelect
                label="Choose complication"
                placeholder="Pick or search complication from list"
                itemComponent={SelectItem}
                {...form.getInputProps('complication')}
                data={data}
                searchable
                nothingFound="Nobody here"
                filter={(value, selected, item) =>
                  !selected &&
                  (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
                    item.description.toLowerCase().includes(value.toLowerCase().trim()))
                }
                />
          </Container>

          <Container
            style={{ marginTop: 50}}
            padding="lg"
          >
              <Text 
                size="sm" 
                weight={500}
                style={{ marginBottom: 4 }}
              
              >Gestation Age</Text>
              <Slider
                  min={13}
                  max={36}
                  style={{ marginBottom: 80 }}
                  {...form.getInputProps('gestation')}
                  marks={[
                  { value: 13, label: '0-13 Weeks' },
                  { value: 26, label: '14-26 Weeks' },
                  { value: 36, label: '27-36 Weeks' },
                ]}
              />

          <Group position="right" mt="xl">
            <Button type="submit">Sumbit</Button>
          </Group>
          </Container>
      </form>
    
     
    </div>
  );
}

export default ComplicationSelect;
